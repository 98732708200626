<template>
  <div class="h-screen flex overflow-hidden bg-gray-100">
    <!-- Transitions-->
    <TransitionRoot as="template" :show="sidebarOpen">
      <Dialog
        as="div"
        static
        class="fixed inset-0 flex z-40 md:hidden"
        @close="sidebarOpen = false"
        :open="sidebarOpen"
      >
        <TransitionChild
          as="template"
          enter="transition-opacity ease-linear duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay class="fixed inset-0 bg-gray-600 bg-opacity-75" />
        </TransitionChild>
        <TransitionChild
          as="template"
          enter="transition ease-in-out duration-300 transform"
          enter-from="-translate-x-full"
          enter-to="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leave-from="translate-x-0"
          leave-to="-translate-x-full"
        >
          <div
            class="
              relative
              flex-1 flex flex-col
              max-w-xs
              w-full
              pt-5
              pb-4
              bg-gray-800
            "
          >
            <TransitionChild
              as="template"
              enter="ease-in-out duration-300"
              enter-from="opacity-0"
              enter-to="opacity-100"
              leave="ease-in-out duration-300"
              leave-from="opacity-100"
              leave-to="opacity-0"
            >
              <div class="absolute top-0 right-0 -mr-12 pt-2">
                <button
                  class="
                    ml-1
                    flex
                    items-center
                    justify-center
                    h-10
                    w-10
                    rounded-full
                    focus:outline-none
                    focus:ring-2 focus:ring-inset focus:ring-white
                  "
                  @click="sidebarOpen = false"
                >
                  <span class="sr-only">Close sidebar</span>
                  <XIcon class="h-6 w-6 text-white" aria-hidden="true" />
                </button>
              </div>
            </TransitionChild>
            <div class="flex-shrink-0 flex items-center px-4">
              <img
                class="mx-auto h-12 w-auto"
                src="@/assets/logo-app-cascajares.svg"
                alt="Cascajares"
              />
            </div>
            <div class="mt-5 flex-1 h-0 overflow-y-auto">
              <nav class="px-2 space-y-1">
                <router-link
                  v-for="item in NAVIGATION_LINKS"
                  :key="item.name"
                  :class="[
                    this.$route.name === item.link.name
                      ? 'bg-gray-900 text-white'
                      : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                    'group flex items-center px-2 py-2 text-sm font-medium rounded-md',
                  ]"
                  :to="item.link"
                  @click="sidebarOpen = false"
                >
                  <font-awesome-icon
                    :icon="item.icon"
                    :class="[
                      this.$route.name === item.link.name
                        ? 'text-gray-300'
                        : 'text-gray-400 group-hover:text-gray-300',
                      'mr-3 h-6 w-6 text-lg fa-fw',
                    ]"
                    :style="{ color: '#f49b00' }"
                    aria-hidden="true"
                  />
                  {{ item.name }}
                </router-link>
              </nav>
            </div>
          </div>
        </TransitionChild>
        <div class="flex-shrink-0 w-14" aria-hidden="true">
          <!-- Dummy element to force sidebar to shrink to fit close icon -->
        </div>
      </Dialog>
    </TransitionRoot>
    <!-- End Transitions-->

    <!-- Static sidebar for desktop -->
    <div class="hidden md:flex md:flex-shrink-0">
      <div class="flex flex-col w-64">
        <!-- Sidebar component, swap this element with another sidebar if you like -->
        <div class="flex flex-col h-0 flex-1">
          <div class="flex items-center h-16 flex-shrink-0 px-4 bg-gray-900">
            <img
              class="mx-auto h-12 w-auto"
              src="@/assets/logo-app-cascajares.svg"
              alt="Cascajares"
            />
          </div>
          <div class="flex-1 flex flex-col overflow-y-auto">
            <nav class="flex-1 px-2 py-4 bg-gray-800 space-y-1">
              <router-link
                v-for="item in NAVIGATION_LINKS"
                :key="item.name"
                :class="[
                  this.$route.name === item.link.name
                    ? 'bg-gray-900 text-white'
                    : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                  'group flex items-center px-2 py-2 text-sm font-medium rounded-md',
                ]"
                :to="item.link"
              >
                <font-awesome-icon
                  :icon="item.icon"
                  :class="[
                    this.$route.name === item.link.name
                      ? 'text-gray-300'
                      : 'text-gray-400 group-hover:text-gray-300',
                    'mr-3 h-6 w-6 text-lg fa-fw',
                  ]"
                  :style="{ color: '#f49b00' }"
                  aria-hidden="true"
                />
                {{ item.name }}
              </router-link>
            </nav>
          </div>
        </div>
      </div>
    </div>

    <div class="flex flex-col w-0 flex-1 overflow-hidden">
      <div class="relative z-10 flex-shrink-0 flex h-16 bg-white shadow">
        <button
          class="
            px-4
            border-r border-gray-200
            text-gray-500
            focus:outline-none
            focus:ring-2 focus:ring-inset focus:ring-indigo-500
            md:hidden
          "
          @click="sidebarOpen = true"
        >
          <span class="sr-only">Open sidebar</span>
          <MenuAlt2Icon class="h-6 w-6" aria-hidden="true" />
        </button>
        <div class="flex-1 px-4 flex justify-between">
          <div class="flex-1 flex">
            <label for="search_field" class="sr-only">Buscar</label>
            <div
              class="relative w-full text-gray-400 focus-within:text-gray-600"
            >
              <div
                class="
                  absolute
                  inset-y-0
                  left-0
                  flex
                  items-center
                  pointer-events-none
                "
              >
                <SearchIcon class="h-5 w-5" aria-hidden="true" />
              </div>
              <input
                id="search_field"
                class="
                  block
                  w-full
                  h-full
                  pl-8
                  pr-3
                  py-2
                  border-transparent
                  text-gray-900
                  placeholder-gray-500
                  focus:outline-none
                  focus:placeholder-gray-400
                  focus:ring-0
                  focus:border-transparent
                  sm:text-sm
                "
                placeholder="Buscar"
                type="search"
                name="search"
                v-model="searchTerm"
                @keyup.enter.prevent="doSearch"
              />
            </div>
          </div>
        </div>
      </div>

      <main class="flex-1 relative overflow-y-auto focus:outline-none">
        <div class="py-6">
          <div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
            <router-view />
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import { computed, ref } from "vue";
import {
  Dialog,
  DialogOverlay,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import { MenuAlt2Icon, XIcon } from "@heroicons/vue/outline";
import { SearchIcon } from "@heroicons/vue/solid";
import { NAVIGATION_LINKS } from "@/config/parameters";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faHome,
  faList,
  faFolderOpen,
  faPlayCircle,
  faUtensils,
} from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add([faHome, faUtensils, faList, faPlayCircle, faFolderOpen]);

import { useRouter } from "vue-router";

export default {
  components: {
    Dialog,
    DialogOverlay,
    TransitionChild,
    TransitionRoot,
    MenuAlt2Icon,
    SearchIcon,
    XIcon,
    FontAwesomeIcon,
  },
  setup() {
    const sidebarOpen = ref(false);
    const router = useRouter();
    const currentRoute = computed(() => router.currentRoute.value.name);
    const routerKey = ref(0);
    const searchTerm = ref("");

    const doSearch = (event) => {
      event.preventDefault();
      router.push({ name: "Buscar", query: { q: searchTerm.value } });
    };

    return {
      NAVIGATION_LINKS,
      sidebarOpen,
      doSearch,
      currentRoute,
      routerKey,
      searchTerm,
    };
  },
};
</script>
