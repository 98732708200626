import { createRouter, createWebHistory } from "vue-router";
import Inicio from "../views/Inicio.vue";

const routes = [
  {
    path: "/",
    name: "Inicio",
    component: Inicio,
  },
  {
    path: "/productos",
    name: "Productos",
    component: () => import("../views/Productos.vue"),
  },
  {
    path: "/productos/:slug",
    name: "Producto",
    component: () => import("../views/Producto.vue"),
  },
  {
    path: "/catalogos",
    name: "Catalogos",
    component: () => import("../views/Catalogos.vue"),
  },
  {
    path: "/catalogos/:slug",
    name: "Catalogo",
    component: () => import("../views/Catalogo.vue"),
  },
  {
    path: "/videos",
    name: "Videos",
    component: () => import("../views/Videos.vue"),
  },
  {
    path: "/recetas",
    name: "Recetas",
    component: () => import("../views/Recetas.vue"),
  },
  {
    path: "/recetas/:slug",
    name: "Receta",
    component: () => import("../views/Receta.vue"),
  },
  {
    path: "/buscar",
    name: "Buscar",
    component: () => import("../views/Buscar.vue"),
  },
  {
    path: "/:catchAll(.*)",
    name: "NotFound",
    component: () => import("../views/404.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
