import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";

import "tailwindcss/tailwind.css";
import "@/assets/main.css";

const app = createApp(App);

app.use(router);
app.config.globalProperties.$filters = {
  capitalize(string) {
    return string
      ? string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
      : "";
  },
};
app.mount("#app");
