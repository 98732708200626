<template>
  <TransitionRoot
    :show="true"
    enter="transition-opacity duration-500"
    enter-from="opacity-0"
    enter-to="opacity-100"
  >
    <ul
      class="
        grid grid-cols-1
        gap-x-4 gap-y-4
        md:grid-cols-2
        md:gap-x-8 md:gap-y-8
        lg:grid-cols-2
        lg:gap-x-8 lg:gap-y-8
      "
    >
      <li v-for="item in NAVIGATION_LINKS" :key="item.name">
        <router-link
          class="
            bg-gray-800
            text-gray-300
            hover:bg-gray-700
            hover:text-white
            grid
            items-center
            px-6
            py-6
            h-52
            md:h-60
            text-lg
            rounded-md
          "
          :to="item.link"
        >
          <font-awesome-icon
            :icon="item.icon"
            class="fa-fw mx-auto"
            size="5x"
            :style="{ color: '#f49b00' }"
            aria-hidden="true"
          />
          <p class="text-center font-zeyada text-4xl">{{ item.name }}</p>
        </router-link>
      </li>
    </ul>
  </TransitionRoot>
</template>

<script>
import { TransitionRoot } from "@headlessui/vue";
import { NAVIGATION_LINKS } from "@/config/parameters";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faList,
  faFolderOpen,
  faPlayCircle,
  faUtensils,
} from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add([faUtensils, faList, faPlayCircle, faFolderOpen]);

export default {
  components: {
    TransitionRoot,
    FontAwesomeIcon,
  },
  setup() {
    const NAVIGATION_LINKS_HOME = NAVIGATION_LINKS.filter(
      (item) => item.showAtHome
    );

    return {
      NAVIGATION_LINKS: NAVIGATION_LINKS_HOME,
    };
  },
};
</script>
