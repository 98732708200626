const NAVIGATION_LINKS = [
  {
    name: "Inicio",
    link: { name: "Inicio" },
    icon: ["fad", "home"],
    showAtHome: false,
  },
  {
    name: "Fichas e imágenes",
    link: { name: "Productos" },
    icon: ["fad", "list"],
    showAtHome: true,
  },
  {
    name: "Catálogos de productos",
    link: { name: "Catalogos" },
    icon: ["fad", "folder-open"],
    showAtHome: true,
  },
  {
    name: "Vídeos",
    link: { name: "Videos" },
    icon: ["fad", "play-circle"],
    showAtHome: true,
  },
  {
    name: "Recetas",
    link: { name: "Recetas" },
    icon: ["fad", "utensils"],
    showAtHome: true,
  },
];

const API_BASE_URL = "https://app.cascajares.eu";

const EMAILJS_SERVICE_ID = "service_s6g7ak5";
const EMAILJS_TEMPLATE_ID = "template_arcap7n";
const EMAILJS_USER_ID = "user_ouEdZXb7Nzp0W90JAAL9Q";

export {
  NAVIGATION_LINKS,
  API_BASE_URL,
  EMAILJS_SERVICE_ID,
  EMAILJS_TEMPLATE_ID,
  EMAILJS_USER_ID,
};
